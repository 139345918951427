import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import PlaylistAddRoundedIcon from "@mui/icons-material/PlaylistAddRounded";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import {
  Stack,
  Divider,
  List,
  ListItem,
  Box,
  Button,
  Typography,
  SwipeableDrawer,
  IconButton,
} from "@mui/material";
import "./AppMenu.scss";
import { NavLink, useNavigate } from "react-router-dom";

import familyImage from "../../assets/img/family.png";
import { User } from "../../services/openapi";
import { Paths } from "../../utils/constants";
import { UserLogoName } from "../UserLogoName/UserLogoName";

export interface AppMenuProps {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  user: User | null;
  onSignOutClick: () => void;
  onAddListClick: () => void;
}

export const AppMenu = (props: AppMenuProps) => {
  const handleCreateListClick = () => {
    props.onAddListClick();
    props.onClose();
  };
  const navigate = useNavigate();

  return (
    <SwipeableDrawer
      className="menu-drawer"
      anchor="left"
      open={props.open}
      onClose={props.onClose}
      onOpen={props.onOpen}
    >
      <Box className="menu-drawer-logo-container">
        <Button className="menu-drawer-logo-button" variant="contained" color="primary">
          <Typography>Listy</Typography>
        </Button>
        <Box
          className="menu-drawer-logo-image"
          component="img"
          alt="Listy image"
          src={familyImage}
        />
      </Box>
      <Stack className="menu-drawer-login">
        {props.user !== null && props.user.name ? (
          <UserLogoName
            userLogoUrl={props.user.profileUrl || ""}
            userName={props.user.name}
          />
        ) : (
          <NavLink to={Paths.LOGIN_HOME} className="menu-drawer-list-link">
            <Button
              className="menu-drawer-list-button"
              variant="text"
              color="secondary"
              startIcon={<AccountCircleOutlinedIcon />}
            >
              <Typography>Войти</Typography>
            </Button>
          </NavLink>
        )}
      </Stack>
      <Box className="menu-drawer-divider-container">
        <IconButton
          aria-label="close"
          onClick={props.onClose}
          className={"menu-drawer-close " + (props.open ? "active" : "")}
        >
          <NavigateBeforeIcon />
        </IconButton>
        <Divider />
      </Box>

      <List className="menu-drawer-list">
        <ListItem>
          <Button
            className="menu-drawer-list-button"
            variant="text"
            color="primary"
            startIcon={<AddOutlinedIcon />}
            onClick={handleCreateListClick}
          >
            <Typography>Создать новый список</Typography>
          </Button>
        </ListItem>
        <ListItem>
          <Button
            className="menu-drawer-list-button"
            variant="text"
            color="secondary"
            startIcon={<StarBorderOutlinedIcon />}
            onClick={console.log}
          >
            <Typography>PRO-версия</Typography>
          </Button>
        </ListItem>
        <ListItem>
          <Button
            className="menu-drawer-list-button"
            variant="text"
            color="secondary"
            startIcon={<EmailOutlinedIcon />}
            onClick={() => navigate(Paths.SUPPORT)}
          >
            <Typography>Обратная связь</Typography>
          </Button>
        </ListItem>
        <ListItem>
          <Button
            className="menu-drawer-list-button"
            variant="text"
            color="secondary"
            startIcon={<SettingsOutlinedIcon />}
            onClick={console.log}
          >
            <Typography>Настройки</Typography>
          </Button>
        </ListItem>
        <ListItem>
          <Button
            className="menu-drawer-list-button"
            variant="text"
            color="secondary"
            startIcon={<PlaylistAddRoundedIcon />}
            onClick={() => navigate(Paths.FUTURE_FEATURES)}
          >
            <Typography>Будущие функции</Typography>
          </Button>
        </ListItem>
        <ListItem>
          <Button
            className="menu-drawer-list-button"
            variant="text"
            color="secondary"
            startIcon={<HelpOutlineOutlinedIcon />}
            onClick={console.log}
          >
            <Typography>Помощь</Typography>
          </Button>
        </ListItem>
        {props.user && (
          <ListItem>
            <Button
              className="menu-drawer-list-button"
              variant="text"
              color="secondary"
              startIcon={<LogoutOutlinedIcon />}
              onClick={props.onSignOutClick}
            >
              <Typography>Выйти</Typography>
            </Button>
          </ListItem>
        )}
      </List>
    </SwipeableDrawer>
  );
};
