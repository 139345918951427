export enum URL_PARAMS {
  SHARE_LIST_KEY = "shareListKey",
}

export enum LOCAL_STORAGE_KEYS {
  ACCESS_TOKEN = "access_token",
  REFRESH_TOKEN = "refresh_token",
  USER_ID = "user_id",
  USER_EMAIL = "user_email",
  USER_NAME = "user_name",
  USER_LOGO_URL = "user_logo_url",
  INSTALL_BUTTON_LAST_SHOW = "install_button_last_show",
}

export enum Paths {
  LOGIN = "/login",
  LOGIN_HOME = "/login-home",
  SIGN_UP = "/signup",
  SUPPORT = "/support",
  FUTURE_FEATURES = "/future-features",
}

export const emailRegex = /(.+)@(.+){2,}\.(.+){2,}/;

export const CATEGORIES_COLORS: string[] = [
  "#0EA472",
  "#CBBEB5",
  "#423F3B",
  "#525266",
  "#FF6666",
  "#C0392B",
  "#51ABD8",
  "#606060",
  "#6D7782",
  "#838383",
  "#40BD94",
  "#21678D",
  "#1C8986",
  "#272932",
  "#864F9E",
  "#F24848",
  "#4C4C4C",
  "#D9792B",
  "#F2DA33",
  "#69818f",
];
